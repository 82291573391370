import React from 'react';
// import mi from '../../img/mi.png';
import miCr from '../../img/mi-crismass.png';
// import miLove from '../../img/mi-love.png';

const Header = ({ todo, isAnimationPlaying, current, showAll, showPositive, showNegative }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  function calculateTotalTitle(todoList, month, year) {
    let totalTitle = 0;
    if (showAll) {
      totalTitle = todoList
        .filter(item => item.date.getMonth() === month && item.date.getFullYear() === year)
        .reduce((sum, item) => {
          const titleValue = parseInt(item.title) || 0;
          return sum + titleValue;
        }, 0);
    } else if (showPositive) {
      totalTitle = todoList
        .filter(item => item.date.getMonth() === month && item.date.getFullYear() === year)
        .filter(item => parseInt(item.title) >= 0)
        .reduce((sum, item) => {
          const titleValue = parseInt(item.title) || 0;
          return sum + titleValue;
        }, 0);
    } else if (showNegative) {
      totalTitle = todoList
        .filter(item => item.date.getMonth() === month && item.date.getFullYear() === year)
        .filter(item => parseInt(item.title) < 0)
        .reduce((sum, item) => {
          const titleValue = parseInt(item.title) || 0;
          return sum + titleValue;
        }, 0);
    }
    return totalTitle;
  }

  const totalTitle = calculateTotalTitle(todo, currentMonth, currentYear);

  return (
    <div className='header-all'>
      <img src={miCr} alt="mi" className={`mi-img ${isAnimationPlaying ? 'animate' : ''}`} />
      <p className='total-title'>За місяць:<span>{totalTitle}{current}</span></p>
      <h1 className='todo-h1'>NUMERARE</h1>
    </div>
  );
}

export default Header;