import React, { useState } from 'react';
import Accordion from "react-bootstrap/Accordion";
import { deleteDoc, doc } from 'firebase/firestore';

export default function MonthTodo({ months, current, allMonths, formatNumber, db, uid, updateTodos, showDelete }) {
  const [activeKey, setActiveKey] = useState(null);
  const [localMonths, setLocalMonths] = useState([...months]);

  const handleAccordionClick = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  const deleteTodo = async (monthIndex, todoId) => {
    try {
      const todoRef = doc(db, uid, todoId);
      await deleteDoc(todoRef);
      const updatedMonths = [...localMonths];
      const monthToUpdate = updatedMonths[monthIndex];
      const filteredEntries = monthToUpdate.entries.filter(entry => entry.id !== todoId);
      monthToUpdate.entries = filteredEntries;
      monthToUpdate.total = filteredEntries.reduce((acc, entry) => acc + parseInt(entry.title), 0);
      setLocalMonths(updatedMonths);
      updateTodos();
    } catch (error) {
      console.error("Error deleting todo:", error);
    }
  };

  return (
    <div className='block-month'>
      <div className='block-month-all'>
        {localMonths
          .sort((a, b) => b.month - a.month)
          .map((month, index) => (
            <Accordion key={month.month} activeKey={activeKey} onSelect={handleAccordionClick}>
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header>
                  {allMonths[month.month - 1]}:
                  <span className='total-year'>
                    {month.total}{current}{` (${month.entries.length})`}
                  </span>
                </Accordion.Header>
                <Accordion.Body className='fix-month'>
                  {month.entries
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map(record => (
                      <div key={record.id} className='block-view'>
                        <div className='block-left'>
                          <div className="block-date fix-date">
                            {formatNumber(new Date(record.date).getDate())}.{formatNumber(new Date(record.date).getMonth() + 1)}
                          </div>
                          <div className='block-view-title'>
                            {record.title}{current}
                          </div>
                        </div>
                        <div className='todo-view-btn'>
                          {showDelete &&
                            <div className='btn-delete' onClick={() => deleteTodo(index, record.id)}>
                              &#10006;
                            </div>}
                        </div>
                      </div>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
      </div>
    </div>
  );
}
